<template>
  <div>
    <div class="banner">
      <el-carousel indicator-position="none" arrow="never">
        <el-carousel-item height="650" v-for="item in bannerImg" :key="item">
          <img :src="item" :alt="item" />
        </el-carousel-item>
      </el-carousel>
    </div>
    <div class="contBox">
      <div v-for="item in contBox" :key="item.id" @click="itemUrl(item)">
        <img :src="item.detailPicture" alt="" />
        <h3>
          <el-button type="text">{{ item.name }}</el-button>
        </h3>
        <p>{{ item.introduction }}</p>
      </div>
    </div>
  </div>
</template>

<script>
import { findCaseType, findMoreCustomerStories } from '../../api/parentPage'
export default {
  name: '分类更多详情',
  data() {
    return {
      bannerImg: [],
      id: '',
      contBox: []
    }
  },
  created() {
    this.id = this.$route.params.id
    this._initData()
  },
  methods: {
    _initData() {
      // 查询banner图片
      findCaseType({ id: this.id }).then((res) => {
        if (res.status == 200) {
          this.bannerImg = res.data.banner.split(',')
          // console.log(this.bannerImg);
        }
      })
      // 查询种类对应信息
      findMoreCustomerStories({ id: this.id }).then((res) => {
        if (res.status == 200) {
          // console.log(res.data);
          // this.contBox = res.data;
          res.data.forEach((item) => {
            item.detailPicture = item.detailPicture.split(',')[0]
            this.contBox.push(item)
          })
          // console.log( this.contBox);
        }
      })
    },
    itemUrl(item) {
      // this.$router.push({ path: `/categoryCaseDetails/categoryCaseDetails/${item.groupId}/${item.id}` });
      this.$router.push({
        path: `/categoryCaseDetails/更多种类/${this.id}/${item.id}`
      })
    }
  }
}
</script>

<style lang="less" scoped>
.banner {
  width: 100%;
  text-align: center;
  ::v-deep .el-carousel > .el-carousel__container {
    height: 650px !important;
    .el-carousel__item {
      width: 100%;
      text-align: center;
      > img {
        width: 100%;
        height: 650px;
        object-fit: cover;
      }
    }
  }
}
.contBox {
  width: 1200px;
  // padding: 150px 0px 100px;
  margin: auto;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  padding: 60px 0;
  box-sizing: border-box;
  cursor: pointer;
  > div {
    width: 350px;
    margin-bottom: 40px;
    overflow: hidden;
    text-align: start;
    // margin:0px 20px 30px;
    margin-right: 50px;
    // box-sizing: border-box;
    //  &:hover{
    //     border-radius: 15px;
    //     box-shadow: 0px 0px 6px rgba(51, 51, 51, 0.5);
    //   }
    > img {
      width: 350px;
      height: 280px;
      border-radius: 15px;
    }
    > h3 {
      margin: 10px 0px;
      > button {
        font-weight: 700;
        font-size: 18px;
        color: #484848;
        text-align: start;
        &:hover {
          color: #888888;
        }
        ::v-deep span {
          display: inline-block;
          width: 360px;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
        }
      }
    }
    > p {
      width: 360px;
      font-weight: 500;
      font-size: 14px;
      color: #9a9a9a;
      display: -webkit-box; //对象作为弹性伸缩盒子模型显示
      overflow: hidden; //溢出隐藏
      -webkit-box-orient: vertical; //设置伸缩盒子对象的子元素的排列方式
      -webkit-line-clamp: 2; //设置 块元素包含的文本行数
    }
  }
}
</style>
